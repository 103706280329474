import { type ActionFunctionArgs, json, LoaderFunctionArgs, redirect } from '@remix-run/node'; // or cloudflare/deno // or cloudflare/deno
import { Form, Link, useActionData, useNavigate } from '@remix-run/react';
import { useEffect } from 'react';
import { API_HOST } from '@common/constants';
import { getUserToken } from '@server/login/login.server';
import { useSessionStore } from '@store/provider/session.store.provider';

type ReturnType = {
    errors?: string;
    result_code?: string;
    data?: string;
};

export async function loader({ request }: LoaderFunctionArgs) {
    const codeinfo = await getUserToken(request);

    if (codeinfo) {
        return redirect('/voice');
    }

    return json({});
}

export default function _index() {
    const navigate = useNavigate();

    return (
        <main className="mc">
            <div className="MainBox">
                <button
                    className="MainButton Puple"
                    onClick={(e) => {
                        e.preventDefault();
                        navigate('/p-code');
                    }}
                >
                    Host
                </button>
                <button
                    className="MainButton"
                    onClick={(e) => {
                        e.preventDefault();
                        navigate('/v-code');
                    }}
                >
                    Monitor
                </button>
            </div>
        </main>
    );
}
